/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-undef */
/* eslint-disable no-console */
import React, { useEffect, useLayoutEffect, useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-grid-system';
import { useLocation } from 'react-router-dom';
import {
  AiOutlineDoubleRight, AiOutlineEye, AiOutlineShareAlt, AiOutlineHeart, AiOutlineTag,
} from 'react-icons/ai';
import { Pagination } from 'antd';
import Navbar from '../modules/Navbar';
import Footer from '../components/Footer';
import Card from '../components/Card';
import Loading from './Loading';

const SelectTags = styled.div`
  display: inline-flex;
  align-items: center;
  margin-top: 2em;
`;

const SelectTagsBG = styled.div`
  display: inline-flex;
  align-items: center;
  background-color: #E2D8FD;
  margin-right: 1em;
  padding: 2px 16px 2px 16px;
  border-radius: 20px;
`;

const Tag = styled(AiOutlineTag)`
  color: #616161;
  font-size: 24px;
  margin-right: 6px;
`;

const SelectTagsText = styled.p`
  color: #53457D;
  font-size: 16px;
  margin: 0;
`;

const SelectTagsName = styled.h1`
  font-size: 30px;
  margin: 0;
  cursor: default;
`;

const ContentCard = styled(Card)`
  min-width: 350px;
  margin-top: 2em;
  margin-bottom: 2em;
  padding: 0;
  cursor: pointer;
`;

export type ThumbnailProps = {
  thumbnail?: string
};

const ContentImage = styled.div<ThumbnailProps>`
  width: 100%;
  height: 240px;
  background-image: ${(props) => (props.thumbnail ? `url(${props.thumbnail});` : "url('../assets/images/backgrounds/HeadSection.png');")};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;   
  border-radius: 0;
`;

const ContentText = styled.div`
  display: flex;
  min-height: 240px;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 16px;
`;

const Padding = styled.div`
  padding: 16px 24px 16px 24px;
`;

const ContentTag = styled.p`
  color: #04C35C;
  font-size: 14px;
  font-weight: 500;
  margin: 0;
  cursor: default;
`;

const ContentHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ContentTitle = styled.h1`
  color: #2D3748;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 0.5em;
  cursor: default;
`;

const StyledRow = styled(Row)`
  padding-top: 4px;
`;

const Eye = styled(AiOutlineEye)`
  color: #616161;
  font-size: 20px;
  margin-right: 4px;
  cursor: pointer;
`;

const Share = styled(AiOutlineShareAlt)`
  color: #616161;
  font-size: 20px;
  margin-right: 4px;
  cursor: pointer;
`;

const Heart = styled(AiOutlineHeart)`
  color: #616161;
  font-size: 20px;
  margin-right: 4px;
  cursor: pointer;
`;

const DoubleRight = styled(AiOutlineDoubleRight)`
  font-size: 16px;
  margin-top: 4px;
`;

const Amount = styled.p`
  color: #718096;
  font-size: 10px;
  margin: 0;
  margin-right: 10px;
  padding-top: 2px;
  cursor: default;
`;

const ContentSummary = styled.p`
  color: #718096;
  font-size: 16px;
  margin: 0;
  cursor: default;
`;

const ContentFooter = styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: 24px;
  padding-right: 24px;
`;

const ContentAuthor = styled.p`
  font-size: 16px;
  margin: 0;
  cursor: default;
`;

const ContentDate = styled.p`
  color: #718096;
  font-size: 14px;
  margin: 0;
  cursor: default;
`;

const OuterReadMore = styled.div`
  display: flex;
  align-items: center;
`;

const ReadMore = styled.p`
  font-size: 16px;
  font-weight: bold;
  margin: 0;
  margin-right: 6px;
  cursor: default;
`;

const StyledPagination = styled(Pagination)`
  height: 100px;
  text-align: center;
`;

const TagNavigator = () => {
  const [contentData, setContentData] = useState<any[]>([]);
  const [current, setCurrent] = useState(1);
  const [perPage, setPerPage] = useState(4);
  const [totalPage, setTotalPage] = useState(undefined);
  const [loading, setLoading] = useState(true);

  const { search } = useLocation();
  console.log(search);
  const searchParams = new URLSearchParams(search);
  const tag = searchParams.get('tag');
  console.log(tag);

  const onPageChange = (numPage, perNumPage) => {
    console.log(numPage, perNumPage);
    setCurrent(numPage);
    setPerPage(perNumPage);
  };

  useLayoutEffect(() => {
    (async () => {
      const res = await axios.get(`${process.env.REACT_APP_WEB_API_URL}/api/v1/content/recommend?&page=${current}&per_page=${perPage}`,
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem('token')}`,
          },
        });
      console.log(res);
      setContentData(res.data.result);
      setTotalPage(res.data.total);
    })();
  }, [current, perPage, search]);

  const modDate = (date) => {
    const dateTemp = new Date(date);
    const temp = new Intl.DateTimeFormat('en-US', {
      year: 'numeric', month: 'long', day: '2-digit',
    }).format(dateTemp);
    return temp;
  };

  useEffect(() => {
    setTimeout(() => setLoading(false), 2000);
  }, []);

  return (
    <>
      <Navbar />
      {loading === false ? (
        <div>
          <Container>
            <SelectTags>
              <SelectTagsBG>
                <Tag />
                <SelectTagsText>Tags</SelectTagsText>
              </SelectTagsBG>
              <SelectTagsName>{tag}</SelectTagsName>
            </SelectTags>
            {contentData.map((a) => (
              <ContentCard>
                <Row>
                  <Col sm={12} md={12} lg={5} xl={4}>
                    <ContentImage thumbnail={a.thumbnail_image} />
                  </Col>
                  <Col sm={12} md={12} lg={7} xl={8}>
                    <ContentText>
                      <Padding>
                        {a.content_tag_list !== null && (
                        <ContentTag>{a.content_tag_list.map((b) => `#${b} `)}</ContentTag>
                        )}
                        <ContentHeader>
                          <ContentTitle>{a.title}</ContentTitle>
                          <StyledRow>
                            <Eye />
                            <Amount>0</Amount>
                            <Share />
                            <Amount>0</Amount>
                            <Heart />
                            <Amount>0</Amount>
                          </StyledRow>
                        </ContentHeader>
                        <ContentSummary>{a.summary}</ContentSummary>
                      </Padding>
                      <ContentFooter>
                        <OuterReadMore>
                          <ReadMore>Read more</ReadMore>
                          <DoubleRight />
                        </OuterReadMore>
                        <div>
                          <ContentAuthor>{a.create_by}</ContentAuthor>
                          <hr />
                          <ContentDate>{modDate(a.modify_date)}</ContentDate>
                        </div>
                      </ContentFooter>
                    </ContentText>
                  </Col>
                </Row>
              </ContentCard>
            ))}
          </Container>
          <StyledPagination size="small" current={current} onChange={onPageChange} total={totalPage} pageSize={4} />
        </div>
      ) : (
        <Loading />
      )}
      <Footer />
    </>
  );
};

export default TagNavigator;
