/* eslint-disable no-empty */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-undef */
/* eslint-disable no-console */
import React, {
  ForwardedRef, useRef, useCallback, useMemo,
} from 'react';
import dynamic from 'next/dynamic';
import axios from 'axios';
import styled from 'styled-components';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { StringMap } from 'quill';

interface ICustomQuillProps extends StringMap {
  forwardedRef: ForwardedRef<any>;
}

const QuillNoSSRWrapper = dynamic(
  async () => {
    const { default: RQ } = await import('react-quill');
    return ({ forwardedRef, ...props }: ICustomQuillProps) => (
      <RQ ref={forwardedRef} {...props} />
    );
  },
  { ssr: false },
);

const EditorBox = styled(QuillNoSSRWrapper)`
  display: inline-block;
  width: 100%;
  height: 950px;
`;

type Props = {
  contentData: any
  setContentData: any
}

export const Editor = ({
  contentData, setContentData,
}: Props) => {
  const quillRef = useRef<ReactQuill>(null);

  const getFileUrl = async (file: File) => {
    const type = file?.type.split('/')[0];
    console.log(type);
    const form = new FormData();
    console.log(form);
    form.append('file', file);
    form.append('type', type);

    const result = await axios.post(`${process.env.REACT_APP_WEB_API_URL}/api/v1/media`, form, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('token')}`,
      },
    });
    // return result?.data.result.url;
    return `https://opencloudai.com${result?.data.result.url}`;
  };

  const imageHandler = useCallback(() => {
    const input = document.createElement('input');
    console.log(input);
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = async () => {
      const file = input.files?.[0] as File;
      console.log('User trying to uplaod this:', file);
      if (!file) return;

      try {
        const url = await getFileUrl(file);
        console.log(url);
        const quillObj = quillRef?.current?.getEditor();
        console.log(quillObj);
        const range = quillObj?.getSelection();
        console.log(range);
        const index = range?.index || 0;
        console.log(index);

        quillObj?.insertEmbed(index, 'image', url);
      } catch (error: any) { console.log(error); }
    };
  }, []);

  // Modules object for setting up the Quill editor
  const modules = useMemo(() => ({
    toolbar: {
      container: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ align: [] }, { color: [] }, { background: [] }],
        [{ list: 'ordered' }, { list: 'bullet' },
          { indent: '-1' }, { indent: '+1' }],
        ['link', 'image', 'video'],
        ['formula', 'code-block', 'clean'],
      ],
      handlers: {
        image: imageHandler,
      },
    },
    clipboard: {
    // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  }), [imageHandler]);

  const handleChange = (e) => {
    // if (contentData.title === '') return;
    setTimeout(() => setContentData({ ...contentData, content: e }));
    console.log(e);
  };

  return (
    <div>
      <EditorBox
        forwardedRef={quillRef}
        theme="snow"
        value={contentData?.content || ''}
        onChange={handleChange}
        modules={modules}
      />
    </div>
  );
};

export default Editor;
