/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-undef */
/* eslint-disable no-console */
import React from 'react';
import styled from 'styled-components';
import { FcGoogle } from 'react-icons/fc';
import Button from '../components/Button';

const OuterForm1 = styled.div`
  display: table;
  width: 100%;
  height: 100vh;
  background-image: url('assets/images/backgrounds/OpenCloudAIBG.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

const OuterForm2 = styled.div`
  display: table-cell;
  vertical-align: middle;
`;

const Form = styled.div`
  max-width: 350px;
  margin: 0 auto;
`;

const Heading = styled.h1`
  text-align: center;
  color: white;
  font-size: 3em;
  cursor: default;
`;

const Account = styled.h1`
  text-align: center;
  color: white;
  font-size: 1.75em;
  cursor: default;
`;

const Welcome = styled.p`
  text-align: center;
  color: white;
  font-size: 1.5em;
  cursor: default;
`;

const NoAccount = styled.p`
  text-align: center;
  color: white;
  font-size: 1em;
  cursor: default;
`;

const Register = styled.a`
  text-align: center;
  color: white;
  font-size: 1em;
`;

const StyledButton = styled(Button)`
  width: 100%;
  height: 100%;
  justify-content: center;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 1em;
  background-color: #2D3748;
`;

const Google = styled(FcGoogle)`
  vertical-align: middle;
  margin-right: 0.5em;
`;

const Login = () => (
  <OuterForm1>
    <OuterForm2>
      <Form>
        <Heading>OpenCloud AI</Heading>
        <Welcome>Welcome back</Welcome>
        <Account>Login to your account</Account>
        <StyledButton onClick={() => { window.location.href = '/api/v1/google/login'; }}>
          <Google size={16} />
          Log-in with google
        </StyledButton>
        <NoAccount>
          No account?
          <Register href="/register"> Register now!</Register>
        </NoAccount>
      </Form>
    </OuterForm2>
  </OuterForm1>
);

export default Login;
