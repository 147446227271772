/* eslint-disable @typescript-eslint/no-use-before-define */
import React, {
  useEffect, useLayoutEffect, useMemo, useState,
} from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Col, Container, Row } from '../components/StyledGrid';
import theme from '../styles/theme';

function NotFoundPage() {
  return (
    <Container style={{ paddingTop: theme.space[6] }}>
      <Row style={{ marginBottom: theme.space[6] }}>
        <Col>
          <h1>Sorry, the page you requested was not found.</h1>
          <p>Error 404: Page Not Found</p>
          <Link to="/login">Back to Home Page</Link>
        </Col>
      </Row>
    </Container>
  );
}

export default NotFoundPage;
