/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-undef */
/* eslint-disable no-console */
import React from 'react';
import styled from 'styled-components';
import AppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

const StyledContainer = styled(Container)`
  height: 60px;
  max-width: 100%;
  background-color: white;
`;

const StyledTypography = styled(Typography)`
  color: black;
  padding-top: 20px;
`;

const OpenCloudAI = styled.span`
  font-weight: bold;
`;

const Footer = () => (
  <AppBar position="fixed" sx={{ top: 'auto', bottom: 0 }}>
    <StyledContainer maxWidth="xl">
      <StyledTypography variant="body2" sx={{ flexGrow: 1 }}>
        <OpenCloudAI>OpenCloudAI</OpenCloudAI>
        {' '}
        2022 copyright all rights reserved
      </StyledTypography>
    </StyledContainer>
  </AppBar>
);

export default Footer;
