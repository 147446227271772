/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
import styled from 'styled-components';
import ReactLoading from 'react-loading';

const Background = styled.div`
  display: table;
  width: 100%;
  height: 100vh;
  opacity: 0.5;
`;

const AlignMiddle = styled.div`
  display: table-cell;
  vertical-align: middle;
`;

const Center = styled.div`
  text-align: -webkit-center;
`;

const Loading = () => (
  <Background>
    <AlignMiddle>
      <Center>
        <ReactLoading type="spin" color="#1890ff" height="50px" width="50px" />
        <h1>Loading...</h1>
      </Center>
    </AlignMiddle>
  </Background>
);

export default Loading;
