/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-undef */
/* eslint-disable no-console */
import React, { useLayoutEffect, useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { Row, Col } from 'react-grid-system';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import { AiOutlineArrowDown } from 'react-icons/ai';
import Button from '../components/Button';
import Card from '../components/Card';

const Background = styled.div`
  display: table;
  width: 100%;
  height: 525px;
  background-image: url('../assets/images/backgrounds/HeadProfile.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 1em;
  padding: 1.5em;
`;

const OuterCard = styled.div`
  display: table-cell;
  vertical-align: middle;
`;

const StyledCol = styled(Col)`
  display: table;
`;

const InnerCol = styled.div`
  display: table-cell;
  vertical-align: middle;
  padding-left: 3.5em;
  padding-right: 3.5em;
`;

const IconGroup = styled.div`
  margin-bottom: 1em;
`;

const Icon = styled.img`
  width: 40px;
  height: 100%;
`;

const StyledStack = styled(Stack)`
  align-items: center;
  margin-top: 1em;
`;

const StyledCard = styled(Card)`
  max-width: 1150px;
  min-width: 300px;
  background-color: #96969690;
  backdrop-filter: saturate(180%) blur(8px);
  margin: 0 auto;
  box-shadow: none;
`;

const Title = styled.p`
  color: white;
  font-size: 45px;
  margin-bottom: 0;
  cursor: default;
`;

const AboutMe = styled.p`
  color: white;
  cursor: default;
`;

const StyledAvatar = styled(Avatar)`
  margin-bottom: 0.5em;
  justify-content: center;
`;

const EditButton = styled(Button)`
  width: 100%;
  justify-content: center;
  font-size: 16px;
  cursor: pointer;
  background-color: #F0F0F0;
  color: #353535;
  margin-bottom: 1em;
`;

const IconButton = styled(Button)`
  justify-content: center;
  cursor: pointer;
  background-color: transparent;
  margin-right: 0.5em;
  padding: 0;
`;

const ArrowRight = styled(AiOutlineArrowDown)`
  margin-left: 5px
`;

// เปลี่ยนรูปได้

const HeadProfile = () => {
  const [userData, setUserData] = useState<any>(null);

  useLayoutEffect(() => {
    (async () => {
      const res = await axios.get(`${process.env.REACT_APP_WEB_API_URL}/api/v1/profile`,
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem('token')}`,
          },
        });

      console.log(res);
      setUserData(res.data.profile);
    })();
  }, []);

  return (
    <Background>
      <OuterCard>
        <StyledCard>
          <Row>
            <Col sm={6} md={4} lg={3}>
              <StyledStack>
                <Title>{userData?.firstName}</Title>
                <StyledAvatar
                  src={userData?.image}
                  sx={{ width: 150, height: 150 }}
                />
                <EditButton onClick={() => { window.location.href = '/setting'; }}>
                  Edit About Me
                  <ArrowRight />
                </EditButton>
                <IconGroup>
                  <IconButton>
                    <Icon src="../assets/images/icons/linkedin.png" alt="LinkedIn" />
                  </IconButton>
                  <IconButton>
                    <Icon src="../assets/images/icons/fiverr.png" alt="Fiverr" />
                  </IconButton>
                  <IconButton>
                    <Icon src="../assets/images/icons/github.png" alt="Github" />
                  </IconButton>
                  <IconButton>
                    <Icon src="../assets/images/icons/gitlab.png" alt="Gitlab" />
                  </IconButton>
                </IconGroup>
              </StyledStack>
            </Col>
            <StyledCol>
              <InnerCol>
                <AboutMe>Welcome to my blog, and thank you for visiting!</AboutMe>
                <AboutMe>It is my hope that I can share with you the things I am most passionate about in life, and in return, you will share yours as well. </AboutMe>
                <AboutMe>Don’t for get to get connected and comment!</AboutMe>
              </InnerCol>
            </StyledCol>
          </Row>
        </StyledCard>
      </OuterCard>
    </Background>
  );
};

export default HeadProfile;
