/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-undef */
/* eslint-disable no-console */
import React, { useLayoutEffect, useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-grid-system';
import { useLocation } from 'react-router-dom';
import { AiOutlineHeart } from 'react-icons/ai';
import { HiOutlineReply } from 'react-icons/hi';
import Avatar from '@mui/material/Avatar';
import Comment from '../components/Comment';
import Button from '../components/Button';
import Card from '../components/Card';

const StyledContainer = styled(Container)`
  margin-bottom: 100px;
`;

const Header = styled.h1`
  font-size: 24px;
  font-weight: bold;
  cursor: default;
`;

const CommentCard = styled(Card)`
  box-shadow: none;
`;

const User = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1em;
`;

const StyledAvatar = styled(Avatar)`
  background-color: #E8E7E7;
  margin-right: 0.5em;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

const Name = styled.h1`
  font-size: 20px;
  font-weight: bold;
  margin: 0;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const UserComment = styled.p`
  color: #272727;
  font-size: 20px;
  cursor: default;
`;

const Function = styled.div`
  display: flex;
  justify-content: space-between;
`;

const FunctionRow = styled.div`
  display: flex;
`;

const Like = styled.p`
  color: #8991A0;
  font-size: 16px;
  font-weight: bold;
  margin: 0;
  margin-right: 10px;
  cursor: default;
`;

const Amount = styled.p`
  color: #8991A0;
  font-size: 14px;
  font-weight: bold;
  margin: 0;
  margin-right: 6px;
  padding-top: 2px;
  cursor: default;
`;

const ReplyButton = styled(Button)`
  width: 100%;
  justify-content: center;
  color: #8991A0;
  background-color: white;
  font-size: 14px;
  font-weight: bold;
  padding: 0;
  cursor: pointer;
  &:hover {
    color: #1890ff;
  }
`;

const Heart = styled(AiOutlineHeart)`
  color: #616161;
  font-size: 20px;
  margin-right: 4px;
  margin-top: 4px;
  cursor: pointer;
  &:hover {
    color: #1890ff;
  }
`;

const Reply = styled(HiOutlineReply)`
  transform: scaleX(-1);
  margin-right: 4px;
`;

const OuterComment = styled.div`
  margin: 24px 0px 24px 0px;
`;

const CommentSection = () => {
  const [comment, setComment] = useState<any[]>([]);
  const [commentList, setCommentList] = useState<any[]>([]);
  const [reply, setReply] = useState(0);
  const [isShown, setIsShown] = useState(false);

  const { search } = useLocation();
  console.log(search);
  const searchParams = new URLSearchParams(search);
  const id = searchParams.get('id');
  console.log(id);

  const ID = Number(id);
  console.log(ID);

  useLayoutEffect(() => {
    (async () => {
      const res = await axios.get(`${process.env.REACT_APP_WEB_API_URL}/api/v1/engagement/comment?content_id=${id}`,
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem('token')}`,
          },
        });
      console.log(res);
      setCommentList(res.data.result);
    })();
  }, [id, search]);

  const postComment = async (addComment) => {
    const temp = {
      ...addComment, content_id: ID, reply_to: reply,
    };
    console.log(temp);
    const res = await axios.post(`${process.env.REACT_APP_WEB_API_URL}/api/v1/engagement/comment`,
      temp,
      {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem('token')}`,
        },
      });
    console.log(res);
    setComment(res.data.result);
    // window.location.reload();
  };

  const handleClick = () => {
    setIsShown((current) => !current);
  };

  return (
    <StyledContainer>
      <Row>
        <Col>
          <hr />
          <Header>Comments</Header>
          {commentList !== null && commentList.map((a) => (
            <div>
              <CommentCard>
                <User>
                  <StyledAvatar
                    src={a?.user_profile}
                    sx={{ width: 40, height: 40 }}
                  />
                  <Name>{a.user_name}</Name>
                </User>
                <UserComment dangerouslySetInnerHTML={{ __html: a.comment }} />
                <Function>
                  <FunctionRow>
                    <Amount>0</Amount>
                    <Like>like</Like>
                    <ReplyButton onClick={() => { handleClick(); setReply(a.id); }}>
                      <Reply />
                      Reply
                    </ReplyButton>
                  </FunctionRow>
                  <FunctionRow>
                    <Heart />
                  </FunctionRow>
                </Function>
              </CommentCard>
              {a.reply !== null && a.reply.map((b) => (
                <Row>
                  <Col xs={1} />
                  <Col xs={11}>
                    <CommentCard>
                      <User>
                        <StyledAvatar
                          src={b?.user_profile}
                          sx={{ width: 40, height: 40 }}
                        />
                        <Name>{b.user_name}</Name>
                      </User>
                      <UserComment dangerouslySetInnerHTML={{ __html: b.comment }} />
                      <Function>
                        <FunctionRow>
                          <Amount>0</Amount>
                          <Like>like</Like>
                        </FunctionRow>
                        <FunctionRow>
                          <Heart />
                        </FunctionRow>
                      </Function>
                    </CommentCard>
                  </Col>
                </Row>
              ))}
              {a.id === reply && isShown && (
              <Row>
                <Col xs={1} />
                <Col xs={11}>
                  <Comment comment={comment} setComment={setComment} postComment={postComment} />
                </Col>
              </Row>
              )}
            </div>
          ))}
          <OuterComment>
            <Comment comment={comment} setComment={setComment} postComment={postComment} />
          </OuterComment>
        </Col>
      </Row>
    </StyledContainer>
  );
};
export default CommentSection;
