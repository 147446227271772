/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-undef */
/* eslint-disable no-console */
import React, { useEffect, useLayoutEffect, useState } from 'react';
import axios from 'axios';
import 'antd/dist/antd.css';
import styled from 'styled-components';
import { Container } from 'react-grid-system';
import { Layout, Menu } from 'antd';
import Swal from 'sweetalert2';
import Account from '../modules/Account';
import Profile from '../modules/Profile';
import Navbar from '../modules/Navbar';
import Loading from './Loading';

const { Header } = Layout;

const Heading = styled.h1`
  font-size: 30px;
  cursor: default;
`;

const StyledLayout = styled(Layout)`
  background-color: white;
  margin-top: 1em;
  margin-bottom: 2em;
`;

const StyledHeader = styled(Header)`
  padding: 0px
`;

const StyledItem = styled(Menu.Item)`
  font-size: 24px;
  font-weight: 500;
`;

const Setting = () => {
  // ด้านซ้ายจะเป็นค่าที่นำมาใช้ ด้านขวา (setState) จะนำด้านซ้ายมาตั้งค่าหรือเปลี่ยนแปลง
  const [tab, setTab] = useState('Profile');
  const [userData, setUserData] = useState(null);
  const [gender, setGender] = useState(null);
  const [country, setCountry] = useState(null);
  const [loading, setLoading] = useState(true);

  // การ get query string จาก url
  const getQueryVariable = (variable) => {
    const query = window.location.search.substring(1);
    console.log(query);
    const vars = query.split('&');
    console.log(vars);
    for (let i = 0; i < vars.length; i++) {
      const pair = vars[i].split('=');
      console.log(pair);
      if (pair[0] === variable) { return pair[1]; }
    }
    return ('');
  };

  useLayoutEffect(() => {
    (async () => {
      const token = getQueryVariable('token');
      if (token !== '') {
        window.localStorage.setItem('token', token);
        window.location.replace('/setting');
      }

      const res = await axios.get(`${process.env.REACT_APP_WEB_API_URL}/api/v1/profile`,
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem('token')}`,
          },
        });
      console.log(getQueryVariable('token'));

      console.log(res);
      setUserData(res.data.profile);

      const resgender = await axios.get(`${process.env.REACT_APP_WEB_API_URL}/api/v1/meta/gender`,
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem('token')}`,
          },
        });
      console.log(resgender);
      setGender(resgender.data.result);

      const rescountry = await axios.get(`${process.env.REACT_APP_WEB_API_URL}/api/v1/meta/country`,
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem('token')}`,
          },
        });
      console.log(rescountry);
      setCountry(rescountry.data.result);
    })();
  }, []);

  const editProfile = async (editData) => {
    console.log(editData);
    try {
      // เมื่อ save profile จะต้องได้ข้อมูลที่ save มาด้วย
      Swal.fire({
        title: 'Are you sure to save this ?',
        confirmButtonText: 'Yes',
        showCancelButton: true,
      }).then(async (result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          const res = await axios.put(`${process.env.REACT_APP_WEB_API_URL}/api/v1/profile`,
            editData,
            {
              headers: {
                Authorization: `Bearer ${window.localStorage.getItem('token')}`,
              },
            });
          if (res && res.status) {
            if (res.status === 200) {
              Swal.fire({
                title: 'Success!',
                icon: 'success',
                showConfirmButton: false,
                timer: 1000,
              }).then(() => {
                window.location.reload();
              });
            }
          } else {
            Swal.fire({
              title: 'Error!',
              icon: 'error',
              showConfirmButton: false,
              timer: 1000,
            });
          }
        }
      });
    } catch (err) {
      Swal.fire({
        title: 'Error!',
        icon: 'error',
        showConfirmButton: false,
        timer: 1000,
      });
    }
  };

  useEffect(() => {
    setTimeout(() => setLoading(false), 2000);
  }, []);

  return (
    <>
      <Navbar />
      {loading === false ? (
        <Container>
          <StyledLayout>
            <Heading>User settings</Heading>
            <StyledHeader>
              <Menu mode="horizontal" defaultSelectedKeys={['2']}>
                <StyledItem key="1" onClick={() => { setTab('Account'); }}>
                  Account
                </StyledItem>
                <StyledItem key="2" onClick={() => { setTab('Profile'); }}>
                  Profile
                </StyledItem>
              </Menu>
            </StyledHeader>
          </StyledLayout>
          {tab === 'Account' && (
          <Account userData={userData} setUserData={setUserData} editProfile={editProfile} gender={gender} country={country} />
          )}
          {tab === 'Profile' && (
          <Profile userData={userData} setUserData={setUserData} editProfile={editProfile} />
          )}
        </Container>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default Setting;
