/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-undef */
/* eslint-disable no-console */
import React, { useEffect, useLayoutEffect, useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-grid-system';
import { useLocation } from 'react-router-dom';
import { AiOutlineEye, AiOutlineShareAlt, AiOutlineHeart } from 'react-icons/ai';
import { GiReceiveMoney } from 'react-icons/gi';
import CommentSection from '../modules/CommentSection';
import Navbar from '../modules/Navbar';
import Footer from '../components/Footer';
import Button from '../components/Button';
import Loading from './Loading';

const TopText = styled.div`
  display: flex;
  min-height: 240px;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 24px;
`;

const Title = styled.h1`
  color: black;
  font-size: 45px;
  font-weight: bold;
  cursor: default;
`;

export type ThumbnailProps = {
  thumbnail?: string
};

const Background = styled.div<ThumbnailProps>`
  width: 100%;
  height: 380px;
  background-image: ${(props) => (props.thumbnail ? `url(${props.thumbnail});` : "url('../assets/images/backgrounds/HeadSection.png');")};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;   
  margin-top: 2em;
  margin-bottom: 1em;
`;

const Padding = styled.div`
  padding: 16px 24px 16px 24px;
`;

const IconRow = styled(Row)`
justify-content: flex-end !important;
`;

const Eye = styled(AiOutlineEye)`
  color: #616161;
  font-size: 20px;
  margin-right: 4px;
  cursor: pointer;
  &:hover {
    color: #1890ff;
  }
`;

const Share = styled(AiOutlineShareAlt)`
  color: #616161;
  font-size: 20px;
  margin-right: 4px;
  cursor: pointer;
  &:hover {
    color: #1890ff;
  }
`;

const Content = styled.p`
  color: #718096;
  font-size: 16px;
  margin-top: 2em;
  cursor: default;
`;

const ContentDate = styled.p`
  color: #718096;
  font-size: 14px;
  margin: 0;
  cursor: default;
`;

const Heart = styled(AiOutlineHeart)`
  color: #616161;
  font-size: 20px;
  margin-right: 4px;
  cursor: pointer;
  &:hover {
    color: #1890ff;
  }
`;

const Amount = styled.p`
  color: #718096;
  font-size: 10px;
  margin: 0;
  margin-right: 10px;
  padding-top: 2px;
  cursor: default;
`;

const DonateButton = styled(Button)`
  width: 100%;
  justify-content: center;
  color: #5D5FEF;
  background-color: #B9AFDB20;
  border-radius: 20px;
  margin-bottom: 1em;
  cursor: pointer;
`;

const Donate = styled(GiReceiveMoney)`
  color: #5D5FEF;
  font-size: 20px;
  margin-right: 4px;
`;

// กำหนด Type ของ props เพื่อเป็นการฟิกชนิดตัวแปร
type ContentDataProps = {
  id: number;
  title: string
  thumbnail_image: string
  modify_date: null
  content: string
}

const Article = () => {
  const [contentData, setContentData] = useState<ContentDataProps>({
    id: 0,
    title: '',
    thumbnail_image: '',
    modify_date: null,
    content: '',
  });
  const [loading, setLoading] = useState(true);

  const { search } = useLocation();
  console.log(search);

  useLayoutEffect(() => {
    (async () => {
      const searchParams = new URLSearchParams(search);
      const id = searchParams.get('id');
      console.log(id);
      const res = await axios.get(`${process.env.REACT_APP_WEB_API_URL}/api/v1/content?id=${id}`,
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem('token')}`,
          },
        });
      console.log(res);
      const contentDataTemp = {
        id: res.data.result.id,
        title: res.data.result.title,
        thumbnail_image: res.data.result.thumbnail_image,
        modify_date: res.data.result.modify_date,
        content: res.data.result.content,
      };
      setContentData(contentDataTemp);
      console.log(contentDataTemp);
    })();
  }, [search]);

  useEffect(() => {
    setTimeout(() => setLoading(false), 2000);
  }, []);

  const modDate = (date) => {
    const dateTemp = new Date(date);
    const temp = new Intl.DateTimeFormat('en-US', {
      year: 'numeric', month: 'long', day: '2-digit',
    }).format(dateTemp);
    return temp;
  };

  return (
    <>
      <Navbar />
      {loading === false ? (
        <div>
          <Container>
            <Background thumbnail={contentData.thumbnail_image} />
          </Container>
          <Container>
            <Row>
              <Col>
                <TopText>
                  <Padding>
                    <Title>{contentData.title}</Title>
                    <Row>
                      <Col sm={8} md={9} lg={10}>
                        <ContentDate>{modDate(contentData.modify_date)}</ContentDate>
                      </Col>
                      <Col sm={4} md={3} lg={2}>
                        <IconRow>
                          <Heart />
                          <Amount>0</Amount>
                          <Eye />
                          <Amount>0</Amount>
                          <Share />
                          <Amount>0</Amount>
                        </IconRow>
                      </Col>
                    </Row>
                    <hr />
                    <Content dangerouslySetInnerHTML={{ __html: contentData.content }} />
                  </Padding>
                </TopText>
                <Row>
                  <Col sm={7} md={8} lg={9} />
                  <Col sm={5} md={4} lg={3}>
                    <DonateButton>
                      <Donate />
                      Donate
                    </DonateButton>
                  </Col>
                </Row>
                <CommentSection />
              </Col>
            </Row>
          </Container>
        </div>
      ) : (
        <Loading />
      )}
      <Footer />
    </>
  );
};

export default Article;
