/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-undef */
/* eslint-disable no-console */
import React, { useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { Modal } from 'antd';
import Avatar from 'react-avatar-edit';
import AvatarMUI from '@mui/material/Avatar';
import { FaCamera } from 'react-icons/fa';

const StyledAvatarBadge = styled(AvatarMUI)`
  background-color:  #E8E7E7;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

const Camera = styled(FaCamera)`
  color: black;
`;

type Props = {
  userData: any
  setUserData: any
}

const AvatarUpload = ({
  userData, setUserData,
}: Props) => {
  const [preview, setPreview] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);

  const onCrop = (pv) => {
    setPreview(pv);
    console.log(pv);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    const bodyFormData = new FormData();
    const byteCharacters = window.atob(preview.replace(/^data:image\/(png|jpeg|jpg);base64,/, ''));
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'image/png' });
    console.log(blob);

    bodyFormData.append('file', blob);
    bodyFormData.append('type', 'image');

    axios({
      method: 'post',
      url: `${process.env.REACT_APP_WEB_API_URL}/api/v1/media`,
      data: bodyFormData,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('token')}`,
      },
    })
      .then((response) => {
        const temp = { ...userData, image: process.env.REACT_APP_WEB_API_URL + response.data.result.url };
        setUserData(temp);
        setIsModalVisible(false);
        console.log(temp);
      })
      .catch((response) => {
      // handle error
        console.log(response);
      });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <StyledAvatarBadge onClick={showModal}>
        <Camera />
      </StyledAvatarBadge>
      <Modal title="Change avatar" width={600} visible={isModalVisible} onOk={() => { handleOk(); }} onCancel={handleCancel}>
        <Avatar
          width={550}
          height={300}
          onCrop={onCrop}
        />
      </Modal>
    </>
  );
};

export default AvatarUpload;
