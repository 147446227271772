/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-undef */
/* eslint-disable no-console */
import React, { useState } from 'react';
import styled from 'styled-components';
import { Modal } from 'antd';
import { ColorPicker, useColor } from 'react-color-palette';
import 'react-color-palette/lib/css/styles.css';
import AvatarMUI from '@mui/material/Avatar';
import { IoColorPalette } from 'react-icons/io5';

// ตั้ง SelectProps เพื่อทำฟังก์ชัน โดยมีตัวแปร color เก็บค่าเป็น string
export type SelectProps = {
  color?: string
};

// กำหนด Styled component เพื่อทำ CSS
const StyledAvatarBadge = styled(AvatarMUI)<SelectProps>`
  background-color:  #E8E7E7;
  cursor: pointer;
  border: ${(props) => (props.color ? '4px solid #04C35C;' : 'none;')};
`;

const Color = styled(IoColorPalette)`
  color: black;
`;

// นำตัวแปรที่มีการกำหนดไว้จากหน้า Setting เข้ามาใช้ในหน้านี้
type Props = {
  userData: any
  setUserData: any
}

const ProfileColour = ({
  userData, setUserData,
}: Props) => {
  // ตั้งตัวแปร state เพื่อทำการแสดง Modal โดยกำหนดเป็น Boolean
  const [isModalVisible, setIsModalVisible] = useState(false);
  // ตั้งตัวแปร state ของ useColor เพื่อทำการเลือก Color โดยกำหนดเป็นรูปแบบ Hex ที่มีค่าเป็น String
  const [color, setColor] = useColor('hex', '');

  // ฟังก์ชัน showModal เพื่อทำการแสดง Modal เมื่อกดปุ่ม
  const showModal = () => {
    setIsModalVisible(true);
  };

  // ฟังก์ชัน handleOk เพื่อทำการยืนยันการเลือกสี และปิด Modal เมื่อกดปุ่ม OK
  const handleOk = () => {
    // ตั้งตัวแปร temp เพื่อกำหนดว่ามีการนำข้อมูลจาก userData ของ profileColor มาใช้ โดยใช้ข้อมูลของ color.hex
    const temp = { ...userData, profileColor: color.hex };
    setUserData(temp);
    setIsModalVisible(false);
    console.log(temp);
  };

  // ฟังก์ชัน handleCancel เพื่อทำการปิด Modal เมื่อกดปุ่ม Cancel
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <StyledAvatarBadge onClick={showModal} color={userData?.profileColor}>
        <Color />
      </StyledAvatarBadge>
      <Modal title="Change color" width={600} visible={isModalVisible} onOk={() => { handleOk(); }} onCancel={handleCancel}>
        <ColorPicker width={550} height={300} color={color} onChange={setColor} hideHSV dark />
      </Modal>
    </>
  );
};

export default ProfileColour;
