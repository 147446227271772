/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-undef */
/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { Row, Col } from 'react-grid-system';
import { AiOutlineTag } from 'react-icons/ai';
import {
  Form, Input, Menu, Dropdown,
} from 'antd';
import Swal from 'sweetalert2';
import CreatableSelect from 'react-select/creatable';
import Navbar from '../modules/Navbar';
import HeadSection from '../modules/HeadSection';
import Editor from '../components/Editor';
import Footer from '../components/Footer';
import Button from '../components/Button';
import Loading from './Loading';

const OuterForm1 = styled.div`
  display: table;
  width: 100%;
`;

const OuterForm2 = styled.div`
  display: table-cell;
  vertical-align: middle;
`;

const Layout = styled.div`
  margin: 0 auto;
  width: 80vw;
`;

const OuterButton = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const StyledRow = styled(Row)`
  justify-content: end !important;
  margin-top: 1em;
  margin-bottom: 65px;
`;

const AddTagsButton = styled(Button)`
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  color: #000000;
  background-color: #FFFFFF;
  margin-bottom: 1em;
  margin-left: 1.5em;
  padding: 0;
`;

const Tag = styled(AiOutlineTag)`
  margin-right: 5px
`;

const StyledMenu = styled(Menu)`
  padding: 1em 1em 0 1em;
`;

const SaveButton = styled(Button)`
  width: 100%;
  justify-content: center;
  font-size: 16px;
  cursor: pointer;
  background-color: #04C35C;
  margin-bottom: 0.5em;
`;

const CancelButton = styled(Button)`
  width: 100%;
  justify-content: center;
  font-size: 16px;
  cursor: pointer;
  color: #000000;
  background-color: #FFFFFF;
  border: 1px solid #000000;
  margin-bottom: 1em;
`;

const SaveDraftButton = styled(Button)`
  width: 100%;
  justify-content: center;
  font-size: 16px;
  cursor: pointer;
  color: #000000;
  background-color: #FFFFFF;
  border: 1px solid #000000;
  margin-bottom: 1em;
`;

const PublishButton = styled(Button)`
  width: 100%;
  justify-content: center;
  font-size: 16px;
  cursor: pointer;
  background-color: #04C35C;
  margin-bottom: 1em;
`;

const Searchable = styled(CreatableSelect)`
  width: 100%;
  margin-bottom: 1em;
`;

const ContentTag = styled.p`
  color: #04C35C;
  font-weight: 500;
`;

// กำหนด Type ของ props เพื่อเป็นการฟิกชนิดตัวแปร
type ContentDataProps = {
  title: string
  summary: string
  // eslint-disable-next-line camelcase
  thumbnail_image: string
  // eslint-disable-next-line camelcase
  content_tag_list: string[]
  content: string
  status: string
}

const CreateContent = () => {
  // set default value ของ object ที่เราจะใช้
  const [contentData, setContentData] = useState<ContentDataProps>({
    title: '',
    summary: '',
    thumbnail_image: '',
    content_tag_list: [],
    content: '',
    status: '',
  });
  const [tag, setTag] = useState<string[]>([]);
  const [visible, setVisible] = useState(false);
  const [tagList, setTagList] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);

  const saveContent = async (addData, status) => {
    const regex = /(<([^>]+)>)/gi;
    const newString = contentData.content.replace(regex, '');
    const temp = { ...addData, status, summary: newString.substring(0, 100) };
    console.log(temp);
    try {
      Swal.fire({
        title: 'Are you sure to create this content ?',
        confirmButtonText: 'Yes',
        showCancelButton: true,
      }).then(async (result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          const res = await axios.post(`${process.env.REACT_APP_WEB_API_URL}/api/v1/content`,
            temp,
            {
              headers: {
                Authorization: `Bearer ${window.localStorage.getItem('token')}`,
              },
            });
          if (res && res.status) {
            if (res.status === 200) {
              Swal.fire({
                title: 'Success!',
                icon: 'success',
                showConfirmButton: false,
                timer: 1000,
              }).then(() => {
                window.location.href = '/my-content';
              });
            }
          } else {
            Swal.fire({
              title: 'Error!',
              icon: 'error',
              showConfirmButton: false,
              timer: 1000,
            });
          }
        }
      });
    } catch (err) {
      Swal.fire({
        title: 'Error!',
        icon: 'error',
        showConfirmButton: false,
        timer: 1000,
      });
    }
  };

  useEffect(() => {
    (async () => {
      const restaglist = await axios.get(`${process.env.REACT_APP_WEB_API_URL}/api/v1/meta/tag_list?length=20`,
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem('token')}`,
          },
        });
      console.log(restaglist);
      setTagList(restaglist.data.result);
    })();
  }, []);

  useEffect(() => {
    setTimeout(() => setLoading(false), 2000);
  }, []);

  // Searchable input

  const handleChange = (e) => {
    const temp = e.map((a) => a.value);
    console.log(temp);
    setTag([...temp]);
  };

  const options = tagList.map((a) => ({ value: a, label: a }));
  console.log(options);

  const handleVisibleChange = (flag) => {
    setVisible(flag);
  };

  const addTag = (
    <StyledMenu>
      <p>Add or change tags (up to 5) so</p>
      <p>readers know what your story is about: </p>
      <Searchable
        isMulti
        placeholder="Add tags..."
        onChange={handleChange}
        options={options}
      />
      <Row>
        <Col>
          <SaveButton onClick={() => { setContentData({ ...contentData, content_tag_list: tag }); setVisible(false); }}>Save</SaveButton>
        </Col>
        <Col>
          <CancelButton onClick={() => { setVisible(false); }}>Cancel</CancelButton>
        </Col>
      </Row>
    </StyledMenu>
  );

  return (
    <>
      <Navbar />
      {loading === false ? (
        <div>
          <HeadSection contentData={contentData} setContentData={setContentData} />
          <Form onFinish={() => { saveContent(contentData, 'PUBLISH'); }}>
            <OuterForm1>
              <OuterForm2>
                <Layout>
                  <OuterButton>
                    <ContentTag>{contentData.content_tag_list.map((a) => `#${a} `)}</ContentTag>
                    <Dropdown overlay={addTag} trigger={['click']} placement="bottomRight" onVisibleChange={handleVisibleChange} visible={visible}>
                      <AddTagsButton onClick={(e) => e.preventDefault()}>
                        <Tag />
                        Add tags
                      </AddTagsButton>
                    </Dropdown>
                  </OuterButton>
                  <Form.Item
                    name="Title"
                    rules={[
                      {
                        required: true,
                        message: 'Please input title!',
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      placeholder="Title"
                      defaultValue={contentData?.title}
                      onChange={(e) => {
                        // if (contentData.title === '') return;
                        const temp = { ...contentData, title: e.target.value };
                        setContentData(temp);
                        console.log(temp);
                      }}
                    />
                  </Form.Item>
                  <Editor contentData={contentData} setContentData={setContentData} />
                  <StyledRow>
                    <Col md={2}>
                      <CancelButton onClick={() => { window.location.href = '/my-content'; }}>Cancel</CancelButton>
                    </Col>
                    <Col md={5} lg={6} />
                    <Col md={3} lg={2}>
                      <SaveDraftButton onClick={() => { saveContent(contentData, 'DRAFT'); }}>Save Draft</SaveDraftButton>
                    </Col>
                    <Col md={2}>
                      <PublishButton type="submit">Publish</PublishButton>
                    </Col>
                  </StyledRow>
                </Layout>
              </OuterForm2>
            </OuterForm1>
          </Form>
        </div>
      ) : (
        <Loading />
      )}
      <Footer />
    </>
  );
};

export default CreateContent;
