import { Row as RGSRow, Col as RGSCol, Container as RGSContainer } from 'react-grid-system';
import theme from '../styles/theme';

export const Row = (props: any) => <RGSRow style={{ marginBottom: theme.space[2] }} {...props} />;
export const Col = (props: any) => <RGSCol style={{ marginBottom: theme.space[2] }} {...props} />;
export const Container = (props: any) => <RGSContainer style={{ marginBottom: theme.space[4] }} {...props} />;

export default {
  Row, Col, Container,
};
