/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-undef */
/* eslint-disable no-console */
import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-grid-system';
import { Input } from 'antd';
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import Stack from '@mui/material/Stack';
import '@mui/material/colors';
import AvatarUpload from './AvatarUpload';
import ColorPicker from './ColorPicker';
import Button from '../components/Button';

const { TextArea } = Input;

// กำหนด Styled component เพื่อทำ CSS
const Heading = styled.h2`
  font-size: 24px;
  cursor: default;
`;

const Info = styled.p`
  font-size: 16px;
  cursor: default;
`;

const Label = styled.p`
  font-size: 16px;
  color: #4A5568;
  margin-top: 2px;
  margin-bottom: 2px;
  cursor: default;
`;

const Optional = styled.p`
  font-size: 16px;
  color: #4A5568;
  margin-top: 2px;
  margin-bottom: 0px;
  cursor: default;
`;

const Describe = styled.p`
  font-size: 10px;
  cursor: default;
`;

const StyledStack = styled(Stack)`
  align-items: center;
  margin-top: 1em;
`;

// ตั้ง AvatarProps เพื่อทำฟังก์ชัน โดยมีตัวแปร color เก็บค่าเป็น string
export type AvatarProps = {
  color?: string
};

const StyledAvatar = styled(Avatar)<AvatarProps>`
  margin-bottom: 0.5em;
  border: ${(props) => (props.color ? `8px solid ${props.color};` : 'none;')};
`;

const SelectBlack = styled(Avatar)`
  cursor: pointer;
  border: ${(props) => (props.color === '#000000' ? '4px solid #04C35C' : 'none;')};
`;

const SelectPink = styled(Avatar)`
  cursor: pointer;
  border: ${(props) => (props.color === '#D56EE5' ? '4px solid #04C35C' : 'none;')};
`;

const SelectBlue = styled(Avatar)`
  cursor: pointer;
  border: ${(props) => (props.color === '#5D5FEF' ? '4px solid #04C35C' : 'none;')};
`;

const SelectYellow = styled(Avatar)`
  cursor: pointer;
  border: ${(props) => (props.color === '#FFCC4D' ? '4px solid #04C35C' : 'none;')};
`;

const SelectRed = styled(Avatar)`
  cursor: pointer;
  border: ${(props) => (props.color === '#F45B64' ? '4px solid #04C35C' : 'none;')};
`;

const StyledRow = styled(Row)`
  justify-content: end !important;
`;

const StyledButton = styled(Button)`
  width: 100%;
  justify-content: center;
  font-size: 16px;
  cursor: pointer;
  background-color: #04C35C;
  margin-bottom: 1em;
`;

const StyledTextArea = styled(TextArea)`
  height: 120px;
`;

// นำตัวแปรที่มีการกำหนดไว้จากหน้า Setting เข้ามาใช้ในหน้านี้
type Props = {
  userData: any
  setUserData: any
  editProfile: any
}

const Profile = ({
  userData, setUserData, editProfile,
}: Props) => (
  <Container>
    <Heading>Cuztomize profile</Heading>
    <Info>Profile Information</Info>
    <hr />
    <Row>
      <Col md={5} lg={4}>
        <StyledStack>
          <Badge
            overlap="circular"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            badgeContent={(
              // ส่งข้อมูลของตัวแปรที่กำหนดไว้ไปยังหน้า AvatarUpload
              <AvatarUpload userData={userData} setUserData={setUserData} />
              )}
          >
            <StyledAvatar
            // รับข้อมูลรูปภาพมาจาก userData โดยสนใจที่ image และรับข้อมูลสีมาจาก userData โดยสนใจที่ profileColor
              src={userData?.image}
              sx={{ width: 150, height: 150 }}
              color={userData?.profileColor}
            />
          </Badge>
        </StyledStack>
        <Optional>Display name (Optional)</Optional>
        <Describe>Set a display name. This does not change your username.</Describe>
        <Input
        // รับข้อมูลมาจาก userData โดยสนใจที่ displayName และมีการตั้งตัวแปร temp เพื่อใช้ในการเปลี่ยนข้อมูลของ displayName
          size="large"
          showCount
          maxLength={50}
          value={userData?.displayName}
          onChange={(e) => {
            const temp = { ...userData, displayName: e.target.value };
            setUserData(temp);
          }}
        />
        <Optional>Profile colour</Optional>
        <Describe>Choose your profile’s banner colour.</Describe>
        <Stack direction="row" spacing={1}>
          <SelectBlack
          // รับข้อมูลสีมาจาก userData โดยสนใจที่ profileColor และมีการตั้งตัวแปร temp เพื่อใช้ในการเปลี่ยนข้อมูลของ profileColor ให้มีข้อมูลเป็น #000000
            sx={{ bgcolor: '#000000' }}
            color={userData?.profileColor}
            onClick={() => {
              const temp = { ...userData, profileColor: '#000000' };
              setUserData(temp);
              console.log(temp);
            }}
          />
          <SelectPink
            sx={{ bgcolor: '#D56EE5' }}
            color={userData?.profileColor}
            onClick={() => {
              const temp = { ...userData, profileColor: '#D56EE5' };
              setUserData(temp);
              console.log(temp);
            }}
          />
          <SelectBlue
            sx={{ bgcolor: '#5D5FEF' }}
            color={userData?.profileColor}
            onClick={() => {
              const temp = { ...userData, profileColor: '#5D5FEF' };
              setUserData(temp);
              console.log(temp);
            }}
          />
          <SelectYellow
            sx={{ bgcolor: '#FFCC4D' }}
            color={userData?.profileColor}
            onClick={() => {
              const temp = { ...userData, profileColor: '#FFCC4D' };
              setUserData(temp);
              console.log(temp);
            }}
          />
          <SelectRed
            sx={{ bgcolor: '#F45B64' }}
            color={userData?.profileColor}
            onClick={() => {
              const temp = { ...userData, profileColor: '#F45B64' };
              setUserData(temp);
              console.log(temp);
            }}
          />
          <Avatar>
            <ColorPicker userData={userData} setUserData={setUserData} />
          </Avatar>
        </Stack>
      </Col>
      <Col md={7} lg={8}>
        <Input.Group size="large">
          <Row>
            <Col>
              <Label>Name</Label>
              <Input
                size="large"
                value={userData?.firstName}
                onChange={(e) => {
                  const temp = { ...userData, firstName: e.target.value };
                  setUserData(temp);
                }}
              />
            </Col>
            <Col>
              <Label>Lastname</Label>
              <Input
                size="large"
                value={userData?.lastName}
                onChange={(e) => {
                  const temp = { ...userData, lastName: e.target.value };
                  setUserData(temp);
                }}
              />
            </Col>
          </Row>
        </Input.Group>
        <Label>Email</Label>
        <Input size="large" disabled value={userData?.email} />
        <Optional>About (optional)</Optional>
        <Describe>A brief description of yourself shown on your profile.</Describe>
        <StyledTextArea
          showCount
          maxLength={200}
          value={userData?.aboutMe}
          onChange={(e) => {
            const temp = { ...userData, aboutMe: e.target.value };
            setUserData(temp);
          }}
        />
      </Col>
    </Row>
    <StyledRow>
      <Col md={5} lg={4} xl={3}>
        <StyledButton onClick={() => { editProfile(userData); }}>Save</StyledButton>
      </Col>
    </StyledRow>
  </Container>
);

export default Profile;
