import { lighten, darken, saturate } from 'polished';

const theme = {
  colors: {
    primary: '#EC2027',
    primaryLight: saturate(0.1, lighten(0.1, '#EC2027')),
    primaryDark: darken(0.1, '#EC2027'),
    background: '#a5d1fe',
    text: '#273876',
    // text: '#fff',
    textDark: '#222',
    // text: '#222',
    textSecondary: '#666',
    // textSecondary: '#fffb',
    textDisabled: '#00000088',
    // textDisabled: '#fff8',
    subtleLine: '#273876',
    grayLight: '#ddd',
    gray: '#888',
    grayDark: '#444',
    lightBackground: '#eee',
    white: '#fff',
    green: '#219653',
    greenLight: '#68D87C',
    yellow: '#E0A100',
    yellowLight: '#FFDE79',
    blueRisk: '#29ABE2',
    blue: '#2767a3',
    blueLight: '#98ECFF',
    indigo: '#2B79EF',
    indigoLight: '#BADCFB',
    orange: '#DE6928',
    orangeLight: '#FFCD9E',
    red: '#CC334F',
    redLight: '#FFCACA',
    black: '#000000',
    transparent: 'transparent',
    fadeBlackBackground: '#00000088',
    fadeWhiteBackground: '#ffffff66',
    tableOnHover: '#ffffff8c',
  },
  fontSizes: [12, 14, 16, 20, 24, 32, 36],
  space: [0, 4, 8, 16, 24, 32, 64],
  radii: [0, 4, 8, 16, 24, 32, 64],
  borderWidths: [0, 1, 2, 4],
  shadow: [
    'none',
    '0px 2px 4px rgba(0, 0, 0, 0.2)',
    '0px 4px 8px rgba(0, 0, 0, 0.2)',
  ],
  dimensions: {
    navbarHeight: 60,
    navSidebarWidth: 60,
    navSidebarWidthExpanded: 240,
    sidebarWidth: 460,
    filterFooterWidth: 400,
  },
  transitionTime: {
    default: '0.3s',
    quick: '0.15s',
    slow: '0.6s',
  },
  iconSize: '15px',
  riskScale: [0, 4.3, 6.2, 8.544],
  riskScalePercent: [0, 50.3277, 72.5655, 100],
//   fontSizePreset: {}
};

export default theme;
