/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-undef */
/* eslint-disable no-console */
import React from 'react';
import styled from 'styled-components';
import { FcGoogle } from 'react-icons/fc';
import Button from '../components/Button';

const OuterForm1 = styled.div`
  display: table;
  width: 100%;
  height: 100vh;
  background-image: url('assets/images/backgrounds/OpenCloudAIBG.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

const OuterForm2 = styled.div`
  display: table-cell;
  vertical-align: middle;
`;

const Form = styled.div`
  max-width: 350px;
  margin: 0 auto;
`;

const Heading = styled.h1`
  text-align: center;
  color: white;
  font-size: 3em;
  cursor: default;
`;

const NoAccount = styled.h1`
  text-align: center;
  color: white;
  font-size: 1.75em;
  cursor: default;
`;

const Welcome = styled.p`
  text-align: center;
  color: white;
  font-size: 1.5em;
  cursor: default;
`;

const HaveAccount = styled.p`
  text-align: center;
  color: white;
  font-size: 1em;
  margin-top: 1em;
  cursor: default;
`;

const Login = styled.a`
  text-align: center;
  color: white;
  font-size: 1em;
`;

const StyledButton = styled(Button)`
  width: 100%;
  height: 100%;
  justify-content: center;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 1em;
  background-color: #2D3748;
`;

const Google = styled(FcGoogle)`
  vertical-align: middle;
  margin-right: 0.5em;
`;

export type AlertProps = {
  hide?: boolean
};

const Alert = styled.p<AlertProps>`
  text-align: center;
  color: #EB5969;
  display: ${(props) => (props.hide ? 'none' : 'block')};
  margin-bottom: 0px;
`;

// การ get query string จาก url
const getQueryVariable = (variable) => {
  const query = window.location.search.substring(1);
  console.log(query);
  const vars = query.split('&');
  console.log(vars);
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=');
    console.log(pair);
    if (pair[0] === variable) { return pair[1]; }
  }
  return (false);
};

const Register = () => (
  <OuterForm1>
    <OuterForm2>
      <Form>
        <Heading>OpenCloud AI</Heading>
        <Welcome>Welcome back</Welcome>
        <NoAccount>Don’t have an account?</NoAccount>
        <StyledButton onClick={() => { window.location.href = '/api/v1/google/register'; }}>
          <Google size={16} />
          Sign-up with google
        </StyledButton>
        <Alert hide={!getQueryVariable('failLogin')}>This account does not show up in our system. </Alert>
        <Alert hide={!getQueryVariable('failLogin')}>Please click sign up to register</Alert>
        <HaveAccount>
          Already have an account?
          <Login href="/"> Login!</Login>
        </HaveAccount>
      </Form>
    </OuterForm2>
  </OuterForm1>
);

export default Register;
