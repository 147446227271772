import React from 'react';
import styled from 'styled-components';
import {
  color, ColorProps, flexbox, space, SpaceProps,
} from 'styled-system';
import theme from '../styles/theme';
import { FlexBoxProps } from './FlexBox';

export type CardProps = ColorProps & SpaceProps & FlexBoxProps;

type NavbarProps = {
  children: React.ReactNode;
}

const Card = styled.div<CardProps>`
  ${color}
  ${space}
  ${flexbox}

  border-radius: ${theme.radii[2]}px;
  box-shadow: ${theme.shadow[1]};
  /* backdrop-filter: ${`blur(${theme.space[3]}px)`}; */
`;

Card.defaultProps = {
  p: 4,
  bg: theme.colors.fadeWhiteBackground,
  color: theme.colors.text,
};

export const CardHeader = styled.div<CardProps>`
  ${color}
  ${space}
  ${flexbox}
  border-top-right-radius:  ${theme.radii[2]}px;
  border-top-left-radius:  ${theme.radii[2]}px;
  /* margin: -${theme.space[4]}px; */
`;

CardHeader.defaultProps = {
  bg: theme.colors.fadeWhiteBackground,
};

export default Card;
