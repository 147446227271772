/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-undef */
/* eslint-disable no-console */
import React, {
  useLayoutEffect, useState,
} from 'react';
import axios from 'axios';
import styled from 'styled-components';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';

const StyledContainer = styled(Container)`
  max-width: 100%;
  background-color: white;
`;

const StyledToolbar = styled(Toolbar)`
  color: black;
  background-color: white;
  padding-left: 0px;
  padding-right: 0px;
`;

const StyledButton = styled(Button)`
  text-align: center;
  text-transform: none;
  margin: 0;
  padding: 16px 8px 16px 8px;
`;

const MenuButton = styled(Button)`
  width: 120px;
  text-align: center;
  text-transform: none;
  margin: 0;
  padding: 8px;
`;

const Logo = styled(Avatar)`
  margin-right: 5px;
`;

const OuterDisplay = styled.div`
  display: inline-flex;
`;

const DisplayName = styled.div`
  display: flex;
  border: solid 1px #EEEEEE;
  border-radius: 30px;
  padding: 1px;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

const Username = styled(Typography)`
  width: 50px;
  color: #616161;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
`;

const NameBG = styled.div`
  background-color: white;
  padding: 12px 30px 12px 30px;
  box-shadow: inset 95px 0 #EEEEEE;
  border-radius: 30px 0 0 30px;
`;

const AvatarBG = styled.div`
  background-color: #EEEEEE;
  margin-left: -10px;
  padding: 0 5px 0 10px;
  border-radius: 30px;
`;

const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

// Get API Profile ที่ Navbar
const Navbar = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [userData, setUserData] = useState<any>(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  useLayoutEffect(() => {
    (async () => {
      const res = await axios.get(`${process.env.REACT_APP_WEB_API_URL}/api/v1/profile`,
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem('token')}`,
          },
        });

      console.log(res);
      setUserData(res.data.profile);
    })();
  }, []);

  return (
    <AppBar position="sticky">
      <StyledContainer maxWidth="xl">
        <StyledToolbar>
          <Typography
            variant="h5"
            noWrap
            component="div"
            sx={{ flexGrow: 10, mr: 2, display: { xs: 'none', md: 'flex' } }}
          >
            <Logo
              src="../../assets/images/icons/CloudMail.png"
              sx={{ width: 30, height: 30 }}
            />
            OpenCloud AI
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              <MenuButton
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: 'black', display: 'block' }}
              >
                About Us
              </MenuButton>
              <MenuButton
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: 'black', display: 'block' }}
                href="/home"
              >
                Home
              </MenuButton>
              <MenuButton
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: 'black', display: 'block' }}
                href="/my-content"
              >
                Blog
              </MenuButton>
              <MenuButton
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: 'black', display: 'block' }}
              >
                Playlist
              </MenuButton>
              <MenuButton
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: 'black', display: 'block' }}
                href="/setting"
              >
                Settings
              </MenuButton>
            </Menu>
          </Box>
          <Typography
            variant="h5"
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'flex', sm: 'none', md: 'none' } }}
          >
            <Logo
              src="../../assets/images/icons/CloudMail.png"
              sx={{ width: 30, height: 30 }}
            />
          </Typography>
          <Typography
            variant="h5"
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'none', sm: 'flex', md: 'none' } }}
          >
            <Logo
              src="../../assets/images/icons/CloudMail.png"
              sx={{ width: 30, height: 30 }}
            />
            OpenCloud AI
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            <StyledButton
              onClick={handleCloseNavMenu}
              sx={{ my: 2, color: 'black', display: 'block' }}
            >
              About Us
            </StyledButton>
            <StyledButton
              onClick={handleCloseNavMenu}
              sx={{ my: 2, color: 'black', display: 'block' }}
              href="/home"
            >
              Home
            </StyledButton>
            <StyledButton
              onClick={handleCloseNavMenu}
              sx={{ my: 2, color: 'black', display: 'block' }}
              href="/my-content"
            >
              Blog
            </StyledButton>
            <StyledButton
              onClick={handleCloseNavMenu}
              sx={{ my: 2, color: 'black', display: 'block' }}
            >
              Playlist
            </StyledButton>
            <StyledButton
              onClick={handleCloseNavMenu}
              sx={{ my: 2, color: 'black', display: 'block' }}
              href="/setting"
            >
              Settings
            </StyledButton>
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <OuterDisplay>
              <DisplayName onClick={handleOpenUserMenu}>
                <NameBG>
                  <Username
                    variant="body2"
                    noWrap
                  >
                    {userData?.displayName}
                  </Username>
                </NameBG>
                <AvatarBG>
                  <Avatar src={userData?.image} />
                </AvatarBG>
              </DisplayName>
            </OuterDisplay>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </StyledToolbar>
      </StyledContainer>
    </AppBar>
  );
};

export default Navbar;
