/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// eslint-disable-next-line
import React, {
  ChangeEvent, SyntheticEvent, useEffect, useState,
} from 'react';
import styled, { ThemeProvider } from 'styled-components';
// import axios from 'axios';
import {
  BrowserRouter, Link, Route, Switch, useHistory, useLocation,
} from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import theme from './styles/theme';
import 'antd/dist/antd.css';
import NotFoundPage from './pages/NotFoundPage';
// import { AssetsDataContext } from './context/AssetDataContext';
// import Main from './pages/Main';
import Login from './pages/Login';
import Register from './pages/Register';
import Setting from './pages/Setting';
import Content from './pages/Content';
import CreateContent from './pages/CreateContent';
import EditContent from './pages/EditContent';
import Home from './pages/Home';
import Article from './pages/Article';
import TagNavigator from './pages/TagNavigator';

// const SidePanel = styled.div`
//   position: absolute;
//   left: ${theme.space[5]}px;
//   top: ${theme.space[6] + theme.space[4]}px;
//   width: 320px;
// `;

function App() {
  // const [isShowSidebar, setShowSidebar] = useState<boolean>(true);
  const [isLoading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    // fetchData();
  }, []);

  return (
    <div>
      <ThemeProvider theme={theme}>
        {/* <Link to="/">Home</Link>
        <Link to="/login">Login</Link> */}
        <Switch>
          {/* <Route path="/" exact component={Main} /> */}
          <Route path="/" exact component={Login} />
          <Route path="/register" exact component={Register} />
          <Route path="/setting" exact component={Setting} />
          <Route path="/my-content" exact component={Content} />
          <Route path="/my-content/new" exact component={CreateContent} />
          <Route path="/my-content/edit" exact component={EditContent} />
          <Route path="/home" exact component={Home} />
          <Route path="/home/article" exact component={Article} />
          <Route path="/home/recommend" exact component={TagNavigator} />
          <Route path="/" component={NotFoundPage} />
        </Switch>
        <ReactTooltip class="myTooltipClass" effect="solid" />
      </ThemeProvider>
    </div>
  );
}

export default App;
