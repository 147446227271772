import React from 'react';
import styled from 'styled-components';
import { darken } from 'polished';
// import Status from '../types/enum/Status.d';
import {
  borderRadius, BorderRadiusProps, color, ColorProps, space, SpaceProps, typography, TypographyProps,
} from 'styled-system';
import theme from '../styles/theme';

export type ButtonProps = {
  // theme: typeof theme,
  children?: React.ReactNode;
  status?: string;
  isFullwidth?: boolean;
  isOutline?: boolean;
  disabled?: boolean;
} & SpaceProps & ColorProps & BorderRadiusProps & TypographyProps;

const Button = styled.button<ButtonProps>`
  ${space}
  ${color}
  ${borderRadius}
  ${typography}

  border: none;
  display: inline-flex;
  align-items: center;

  *:not(:last-child) {
    margin-right: ${theme.space[1]}px;
  }

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.6;
  }
`;

Button.defaultProps = {
  px: 3,
  py: 2,
  bg: 'primary',
  color: 'white',
  borderRadius: 2,
  fontSize: 2,
};

export default Button;
