/* eslint-disable no-empty */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-undef */
/* eslint-disable no-console */
import React, { ForwardedRef, useRef } from 'react';
import dynamic from 'next/dynamic';
import axios from 'axios';
import styled from 'styled-components';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { StringMap } from 'quill';
import { Row, Col } from 'react-grid-system';
import Button from './Button';

interface ICustomQuillProps extends StringMap {
  forwardedRef: ForwardedRef<any>;
}

const QuillNoSSRWrapper = dynamic(
  async () => {
    const { default: RQ } = await import('react-quill');
    return ({ forwardedRef, ...props }: ICustomQuillProps) => (
      <RQ ref={forwardedRef} {...props} />
    );
  },
  { ssr: false },
);

const SendButton = styled(Button)`
  width: 100%;
  justify-content: center;
  color: white;
  background-color: #BDBDBD;
  margin-top: 8px;
  cursor: pointer;
  &:hover {
    background-color: #1890ff;
  }
`;

const StyledRow = styled(Row)`
  justify-content: end !important;
`;

type Props = {
  comment: any
  setComment: any
  postComment: any
}

export const Comment = ({
  comment, setComment, postComment,
}: Props) => {
  const quillRef = useRef<ReactQuill>(null);

  const getFileUrl = async (file: File) => {
    const type = file?.type.split('/')[0];
    console.log(type);
    const form = new FormData();
    console.log(form);
    form.append('file', file);
    form.append('type', type);

    const result = await axios.post(`${process.env.REACT_APP_WEB_API_URL}/api/v1/media`, form, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('token')}`,
      },
    });
    // return result?.data.result.url;
    return `https://opencloudai.com${result?.data.result.url}`;
  };

  const imageHandler = () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = async () => {
      const file = input.files?.[0] as File;
      console.log('User trying to uplaod this:', file);
      if (!file) return;

      try {
        const url = await getFileUrl(file);
        const quillObj = quillRef?.current?.getEditor();
        const range = quillObj?.getSelection();
        const index = range?.index || 0;

        quillObj?.insertEmbed(index, 'image', url);
      } catch (error: any) { console.log(error); }
    };
  };

  // Modules object for setting up the Quill editor
  const modules = {
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike'],
        ['link', 'image', 'video'],
      ],
      handlers: {
        image: imageHandler,
      },
    },
  };

  const handleChange = (e) => {
    setTimeout(() => setComment({ ...comment, comment: e }));
    console.log(e);
  };

  return (
    <div>
      <QuillNoSSRWrapper
        forwardedRef={quillRef}
        theme="snow"
        placeholder="Type your comment here"
        defaultValue={comment?.comment}
        onChange={handleChange}
        modules={modules}
      />
      <StyledRow>
        <Col sm={3} md={2}>
          <SendButton onClick={() => { postComment(comment); }}>Send</SendButton>
        </Col>
      </StyledRow>
    </div>
  );
};

export default Comment;
