import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import { setConfiguration } from 'react-grid-system';
import {
  Provider as KeepAliveProvider,
  KeepAlive,
} from 'react-keep-alive';
import App from './App';
import reportWebVitals from './reportWebVitals';
import theme from './styles/theme';

setConfiguration({ gutterWidth: theme.space[4] });

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <KeepAliveProvider>
        <App />
      </KeepAliveProvider>
    </BrowserRouter>
  </React.StrictMode>,
  /* eslint-disable-next-line no-undef */
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
