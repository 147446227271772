/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-undef */
/* eslint-disable no-console */
import React, { useState, useLayoutEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import AvatarMUI from '@mui/material/Avatar';
import { FaCamera } from 'react-icons/fa';
import Card from '../components/Card';

const Input = styled('input')({
  display: 'none',
});

export type ThumbnailProps = {
  thumbnail?: string
};

const Background = styled.div<ThumbnailProps>`
  display: table;
  width: 100%;
  height: 525px;
  background-image: ${(props) => (props.thumbnail ? `url(${props.thumbnail});` : "url('../../assets/images/backgrounds/HeadSection.png');")};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 1em;
  cursor: pointer;
`;

const OuterCard = styled.div`
  display: table-cell;
  vertical-align: middle;
`;

const StyledCard = styled(Card)`
  width: 350px;
  height: 425px;
  background-color: #E8E8E8;
  text-align: center;
  margin: 0 auto;
  margin-top: 3em;
  box-shadow: none;
`;

const Title = styled.h1`
  color: black;
  font-size: 30px;
  font-weight: bold;
`;

const Write = styled.img`
  display: block;
  width: 220px;
  margin: 0 auto;
  padding-top: 3.5em;
`;

const StyledAvatarBadge = styled(AvatarMUI)`
  float: right;
  background-color:  #E8E7E7;
  margin: 5px;
`;

const Camera = styled(FaCamera)`
  color: black;
  &:hover {
    opacity: 0.8;
  }
`;

// เปลี่ยนรูปได้
type Props = {
  contentData: any
  setContentData: any
}

const HeadSection = ({
  contentData, setContentData,
}: Props) => {
  const [file, setFile] = useState('../assets/images/backgrounds/HeadSection.png' || undefined);

  const handleChange = (e) => {
    if (e.target.files[0]) setFile(URL.createObjectURL(e.target.files[0]));
    console.log(e.target.files[0]);
    const bodyFormData = new FormData();
    const blob = new Blob([e.target.files[0]], { type: 'image/png' });
    console.log(blob);

    bodyFormData.append('file', blob);
    bodyFormData.append('type', 'image');

    axios({
      method: 'post',
      url: `${process.env.REACT_APP_WEB_API_URL}/api/v1/media`,
      data: bodyFormData,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('token')}`,
      },
    })
      .then((response) => {
        const temp = { ...contentData, thumbnail_image: process.env.REACT_APP_WEB_API_URL + response.data.result.url };
        setContentData(temp);
        console.log(temp);
      })
      .catch((response) => {
      // handle error
        console.log(response);
      });
  };

  const { search } = useLocation();
  console.log(search);
  const searchParams = new URLSearchParams(search);
  const id = searchParams.get('id');
  console.log(id);

  useLayoutEffect(() => {
    (async () => {
      const res = await axios.get(`${process.env.REACT_APP_WEB_API_URL}/api/v1/content?id=${id}`,
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem('token')}`,
          },
        });
      console.log(res);
      setFile(res.data.result.thumbnail_image);
    })();
  }, [id, setContentData]);

  return (
    <label htmlFor="icon-button-file">
      <>{ /* eslint-disable-next-line jsx-a11y/label-has-associated-control */ }</>
      <Input
        accept="image/*"
        id="icon-button-file"
        type="file"
        onChange={handleChange}
      />
      <Background thumbnail={file}>
        <OuterCard>
          <StyledCard>
            <Write src="../../assets/images/icons/Write.png" alt="Write post" />
            <Title>Write A Post</Title>
          </StyledCard>
          <StyledAvatarBadge>
            <Camera />
          </StyledAvatarBadge>
        </OuterCard>
      </Background>
    </label>
  );
};

export default HeadSection;
