/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-undef */
/* eslint-disable no-console */
import React from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-grid-system';
import { Input, Select } from 'antd';
import { AiFillLinkedin, AiFillGithub, AiOutlineGitlab } from 'react-icons/ai';
import { SiFigma } from 'react-icons/si';
import { FaTrash } from 'react-icons/fa';
import Swal from 'sweetalert2';
import Button from '../components/Button';

const { Option } = Select;

const deleteProfile = async () => {
  try {
    // Promp message โชว์ Confirm delete ขึ้นมา
    // if confirm จะเรียก axios.delete
    //  ถ้าสำเร็จก็จะลบข้อมูล และ redirect ไปที่หน้า login
    //  แต่ถ้าไม่จะมี alert แจ้งเตือนขึ้นมา
    // แต่ถ้าไม่ confirm จะทำการ cancel
    Swal.fire({
      title: 'Are you sure to delete this ?',
      confirmButtonColor: '#DB2C2C',
      confirmButtonText: 'Yes',
      showCancelButton: true,
    }).then(async (result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        const res = await axios.delete(`${process.env.REACT_APP_WEB_API_URL}/api/v1/profile`,
          {
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem('token')}`,
            },
          });
        if (res && res.status) {
          if (res.status === 200) {
            Swal.fire({
              title: 'Success!',
              icon: 'success',
              showConfirmButton: false,
              timer: 1000,
            }).then(() => {
              window.location.href = '/login';
            });
          }
        } else {
          Swal.fire({
            title: 'Error!',
            icon: 'error',
            showConfirmButton: false,
            timer: 1000,
          });
        }
      }
    });
  } catch (err) {
    Swal.fire({
      title: 'Error!',
      icon: 'error',
      showConfirmButton: false,
      timer: 1000,
    });
  }
};

const Heading = styled.h2`
  font-size: 24px;
  cursor: default;
`;

const Preference = styled.p`
  font-size: 16px;
  cursor: default;
`;

const Deactivate = styled.p`
  font-size: 16px;
  margin-bottom: 0px;
  cursor: default;
`;

const Label = styled.p`
  font-size: 16px;
  color: #4A5568;
  margin-bottom: 0.5em;
  cursor: default;
`;

const Optional = styled.p`
  font-size: 16px;
  color: #4A5568;
  margin-bottom: 0px;
  cursor: default;
`;

const Describe = styled.p`
  font-size: 10px;
  cursor: default;
`;

const StyledInput = styled(Input)`
  margin-bottom: 1em;
`;

const StyledSelect = styled(Select)`
  width: 100%;
  margin-bottom: 1em;
`;

const StyledRow = styled(Row)`
  justify-content: end !important;
`;

const SaveButton = styled(Button)`
  width: 100%;
  justify-content: center;
  float: right;
  font-size: 16px;
  cursor: pointer;
  background-color: #04C35C;
  margin-bottom: 0.5em;
`;

const DeleteButton = styled(Button)`
  width: 100%;
  justify-content: center;
  float: right;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 1em;
`;

const Trash = styled(FaTrash)`
  margin-right: 0.5em
`;

type Props = {
  userData: any
  setUserData: any
  editProfile: any
  gender: any
  country: any
}

const Account = ({
  userData, setUserData, editProfile, gender, country,
}: Props) => (
  <Container>
    <Heading>Account settings</Heading>
    <Preference>Account preference</Preference>
    <hr />
    <Row>
      <Col md={6}>
        <Label>Email address</Label>
        <StyledInput size="large" disabled value={userData?.email} />
        <Optional>Gender</Optional>
        <Describe>OpenCouldAI will never share this information and only uses it to improve what content you see.</Describe>
        <StyledSelect
          size="large"
          value={userData?.gender}
          onChange={(e) => {
            const temp = { ...userData, gender: e };
            setUserData(temp);
          }}
        >
          {gender.map((a) => (
            <Option value={a.id}>{a.name.en}</Option>
          ))}
        </StyledSelect>
        <Optional>Country</Optional>
        <Describe>This is your primary location.</Describe>
        <StyledSelect
          size="large"
          value={userData?.country}
          onChange={(e) => {
            const temp = { ...userData, country: e };
            setUserData(temp);
          }}
        >
          {country.map((a) => (
            <Option value={a.id}>{a.name.en}</Option>
          ))}
        </StyledSelect>
      </Col>
      <Col md={6}>
        <Label>Link Accounts</Label>
        <StyledInput
          size="large"
          suffix={<AiFillLinkedin />}
          value={userData?.linkin}
          onChange={(e) => {
            const temp = { ...userData, linkin: e.target.value };
            setUserData(temp);
          }}
        />
        <StyledInput
          size="large"
          suffix={<SiFigma />}
          value={userData?.figma}
          onChange={(e) => {
            const temp = { ...userData, figma: e.target.value };
            setUserData(temp);
          }}
        />
        <StyledInput
          size="large"
          suffix={<AiFillGithub />}
          value={userData?.github}
          onChange={(e) => {
            const temp = { ...userData, github: e.target.value };
            setUserData(temp);
          }}
        />
        <StyledInput
          size="large"
          suffix={<AiOutlineGitlab />}
          value={userData?.gitlab}
          onChange={(e) => {
            const temp = { ...userData, gitlab: e.target.value };
            setUserData(temp);
          }}
        />

      </Col>
    </Row>
    <StyledRow>
      <Col md={5} lg={4} xl={3}>
        <SaveButton onClick={() => { editProfile(userData); }}>Save</SaveButton>
      </Col>
    </StyledRow>
    <Deactivate>Deactivate Account</Deactivate>
    <hr />
    <StyledRow>
      <Col md={5} lg={4} xl={3}>
        <DeleteButton onClick={() => { deleteProfile(); }}>
          <Trash size={16} />
          DEACTIVATE ACCOUNT
        </DeleteButton>
      </Col>
    </StyledRow>
  </Container>
);

export default Account;
