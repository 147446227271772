/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-undef */
/* eslint-disable no-console */
import React, { useLayoutEffect, useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-grid-system';
import { AiOutlineTag } from 'react-icons/ai';
import { FaArrowAltCircleDown } from 'react-icons/fa';
import Button from '../components/Button';
import Card from '../components/Card';

const Highlight = styled(Card)`
  min-width: 350px;
  background-color: #A5A6F6;
  margin-top: 1.5em;
  margin-bottom: 1.5em;
  padding: 0;
  border-radius: 0;
`;

const HLDisplay = styled.div`
  display: table;
  height: 500px;
  padding: 36px;
`;

const VerticalMiddle = styled.div`
  display: table-cell;
  vertical-align: middle;
  cursor: default;
`;

const HLHeader = styled.h1`
  font-size: 40px;
  font-weight: bold;
  line-height: 1;
  margin-top: 24px;
  margin-bottom: 24px;
  cursor: default;
`;

const Tag = styled(AiOutlineTag)`
  color: #616161;
  font-size: 24px;
  margin-right: 6px;
`;

const HLTagsBG = styled.div`
  display: inline-flex;
  background-color: #E2D8FD;
  padding: 2px 16px 2px 16px;
  border-radius: 20px;
`;

const HLTagsText = styled.p`
  color: #53457D;
  font-size: 16px;
  margin: 0;
`;

const TagsButton = styled(Button)`
  color: #626262;
  background-color: #FFFFFF;
  border-radius: 20px;
  margin: 4px;
  cursor: pointer;
`;

const HLImage = styled.div`
  width: 100%;
  height: 500px;
  background-image: url('../assets/images/backgrounds/HighlightSection.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;   
`;

const ButtonRow = styled(Row)`
  min-width: 400px;
  justify-content: end !important;
  margin-bottom: 65px;
`;

const SeeMoreButton = styled(Button)`
  color: white;
  background-color: #6B46C1;
  float: right;
  margin-right: 0.75em;
  margin-bottom: 1em;
  cursor: pointer;
`;

const ArrowDown = styled(FaArrowAltCircleDown)`
  color: white;
  font-size: 20px;
  margin-left: 6px;
`;

const HighlightSection = () => {
  const [tagList, setTagList] = useState<string[]>([]);

  useLayoutEffect(() => {
    (async () => {
      const restaglist = await axios.get(`${process.env.REACT_APP_WEB_API_URL}/api/v1/meta/tag_list?length=8`,
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem('token')}`,
          },
        });
      console.log(restaglist);
      setTagList(restaglist.data.result);
    })();
  }, []);

  return (
    <div>
      <Container>
        <Highlight>
          <Row>
            <Col md={12} lg={6}>
              <HLDisplay>
                <VerticalMiddle>
                  <HLTagsBG>
                    <Tag />
                    <HLTagsText>Tags</HLTagsText>
                  </HLTagsBG>
                  <HLHeader>DISCOVER MORE OF WHAT LIKE TO READ </HLHeader>
                  {tagList.map((tag) => (
                    <TagsButton onClick={() => { window.location.href = `/home/recommend?tag=${tag}`; }}>{tag}</TagsButton>
                  ))}
                </VerticalMiddle>
              </HLDisplay>
            </Col>
            <Col md={12} lg={6}>
              <HLImage />
            </Col>
          </Row>
        </Highlight>
      </Container>
      <ButtonRow>
        <Col>
          <SeeMoreButton>
            See more
            <ArrowDown />
          </SeeMoreButton>
        </Col>
      </ButtonRow>
    </div>
  );
};
export default HighlightSection;
