/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-undef */
/* eslint-disable no-console */
import React, { useEffect, useLayoutEffect, useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-grid-system';
import { AiOutlineEye, AiOutlineShareAlt, AiOutlineHeart } from 'react-icons/ai';
import { FaRegComment, FaRegEdit } from 'react-icons/fa';
import { FiTrash } from 'react-icons/fi';
import { Pagination } from 'antd';
import Swal from 'sweetalert2';
import Navbar from '../modules/Navbar';
import HeadProfile from '../modules/HeadProfile';
import Footer from '../components/Footer';
import Card from '../components/Card';
import Loading from './Loading';

const OuterForm1 = styled.div`
  display: table;
  width: 100%;
`;

const OuterForm2 = styled.div`
  display: table-cell;
  vertical-align: middle;
`;

const Layout = styled.div`
  margin: 0 auto;
  width: 80vw;
`;

const StyledCard = styled(Card)`
  min-width: 350px;
  height: 425px;
  background-color: #E8E8E8;
  text-align: center;
  margin-top: 2em;
  margin-bottom: 4em;
  box-shadow: none;
  cursor: pointer;
`;

const Title = styled.h1`
  color: black;
  font-size: 30px;
  font-weight: bold;
  margin-top: 1em;
  cursor: pointer;
`;

const Write = styled.img`
  display: block;
  width: 220px;
  margin: 0 auto;
  padding-top: 2.5em;
`;

// const Video = styled.img`
//   display: block;
//   width: 175px;
//   margin: 0 auto;
//   padding-top: 7em;
// `;

const AddCard = styled(Card)`
  width: 350px;
  height: 425px;
  background-color: #E8E8E8;
  text-align: center;
  cursor: pointer;
  margin: 0 auto;
  margin-top: 1.5em;
  margin-bottom: 1.5em;
  box-shadow: none;
  &:hover {
    opacity: 0.8;
  }
`;

const ContentCard = styled(Card)`
  width: 350px;
  height: 425px;
  margin: 0 auto;
  margin-top: 1.5em;
  margin-bottom: 1.5em;
  padding: 0;
`;

export type ThumbnailProps = {
  thumbnail?: string
};

const ContentImage = styled.div<ThumbnailProps>`
  width: 100%;
  height: 200px;
  background-image: ${(props) => (props.thumbnail ? `url(${props.thumbnail});` : "url('../assets/images/backgrounds/HeadSection.png');")};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;   
  border-radius: 8px 8px 0px 0px;
`;

const PublishedContent = styled.div`
  width: 90px;
  height: 30px;
  background-color: #04C35C;
  color: white;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  border-radius: 8px;
  float: right;
  margin: 5px;
  padding: 5px;
`;

const DraftContent = styled.div`
  width: 90px;
  height: 30px;
  background-color: #969696;
  color: white;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  border-radius: 8px;
  float: right;
  margin: 5px;
  padding: 5px;
`;

const ContentText = styled.div`
  display: flex;
  height: 200px;
  flex-direction: column;
  justify-content: space-between;
`;

const Padding = styled.div`
  padding: 16px 24px 16px 24px;
`;

const ContentTag = styled.p`
  color: #04C35C;
  font-size: 14px;
  font-weight: 500;
  margin: 0;
  cursor: default;
`;

const ContentHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ContentTitle = styled.h1`
  color: #2D3748;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 0.5em;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const ContentIconHead = styled.div`
  display: flex;
  padding-top: 4px;
`;

const Eye = styled(AiOutlineEye)`
  color: #616161;
  font-size: 20px;
  margin-right: 4px;
  cursor: pointer;
  &:hover {
    color: #1890ff;
  }
`;

const Share = styled(AiOutlineShareAlt)`
  color: #616161;
  font-size: 20px;
  margin-right: 4px;
  cursor: pointer;
  &:hover {
    color: #1890ff;
  }
`;

const ContentSummary = styled.p`
  color: #718096;
  font-size: 14px;
  margin: 0;
  cursor: default;
`;

const ContentFooter = styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: 24px;
  padding-right: 24px;
`;

const ContentDate = styled.p`
  color: #718096;
  font-size: 12px;
  margin: 0;
  cursor: default;
`;

const ContentIconFoot = styled.div`
  display: flex;
`;

const Heart = styled(AiOutlineHeart)`
  color: #616161;
  font-size: 20px;
  margin-right: 4px;
  cursor: pointer;
  &:hover {
    color: #1890ff;
  }
`;

const Comment = styled(FaRegComment)`
  color: #616161;
  font-size: 20px;
  margin-right: 4px;
  cursor: pointer;
  &:hover {
    color: #1890ff;
  }
`;

const Edit = styled(FaRegEdit)`
  color: #616161;
  font-size: 20px;
  margin-right: 8px;
  cursor: pointer;
  &:hover {
    color: #1890ff;
  }
`;

const Trash = styled(FiTrash)`
  color: #616161;
  font-size: 20px;
  cursor: pointer;
  &:hover {
    color: #EC2027;
  }
`;

const Amount = styled.p`
  color: #718096;
  font-size: 10px;
  margin: 0;
  margin-right: 10px;
  padding-top: 2px;
  cursor: default;
`;

const StyledPagination = styled(Pagination)`
  height: 100px;
  text-align: center;
`;

const Content = () => {
  const [contentData, setContentData] = useState<any[]>([]);
  const [current, setCurrent] = useState(1);
  const [perPage, setPerPage] = useState(5);
  const [totalPage, setTotalPage] = useState(undefined);
  const [loading, setLoading] = useState(true);

  const onPageChange = (numPage, perNumPage) => {
    console.log(numPage, perNumPage);
    setCurrent(numPage);
    setPerPage(perNumPage);
  };

  useLayoutEffect(() => {
    (async () => {
      const res = await axios.get(`${process.env.REACT_APP_WEB_API_URL}/api/v1/content/my-list?page=${current}&per_page=${perPage}&keyword&author_id&tag&status`,
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem('token')}`,
          },
        });
      console.log(res);
      setContentData(res.data.result);
      setTotalPage(res.data.total);
    })();
  }, [current, perPage]);

  const deleteContent = async (deleteData, status) => {
    const temp = { ...deleteData, status };
    console.log(temp);
    try {
      Swal.fire({
        title: 'Are you sure to delete this content?',
        confirmButtonText: 'Yes',
        showCancelButton: true,
      }).then(async (result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          const res = await axios.delete(`${process.env.REACT_APP_WEB_API_URL}/api/v1/content`,
            {
              headers: {
                Authorization: `Bearer ${window.localStorage.getItem('token')}`,
              },
              data: temp,
            });
          if (res && res.status) {
            if (res.status === 200) {
              Swal.fire({
                title: 'Success!',
                icon: 'success',
                showConfirmButton: false,
                timer: 1000,
              }).then(() => {
                window.location.reload();
              });
            }
          } else {
            Swal.fire({
              title: 'Error!',
              icon: 'error',
              showConfirmButton: false,
              timer: 1000,
            });
          }
        }
      });
    } catch (err) {
      Swal.fire({
        title: 'Error!',
        icon: 'error',
        showConfirmButton: false,
        timer: 1000,
      });
    }
  };

  useEffect(() => {
    setTimeout(() => setLoading(false), 2000);
  }, []);

  const modDate = (date) => {
    const dateTemp = new Date(date);
    const temp = new Intl.DateTimeFormat('en-US', {
      year: 'numeric', month: 'long', day: '2-digit',
    }).format(dateTemp);
    return temp;
  };

  return (
    <>
      <Navbar />
      {loading === false ? (
        <div>
          <HeadProfile />
          {contentData.length === 0 && (
          <OuterForm1>
            <OuterForm2>
              <Layout>
                <Title>My Blog</Title>
                <hr />
                <StyledCard onClick={() => { window.location.href = '/my-content/new'; }}>
                  <Write src="../assets/images/icons/Write.png" alt="Write post" />
                  <Title>Create My First Post</Title>
                </StyledCard>
                {/* <Title>My Playlist</Title>
              <hr />
              <StyledCard>
                <Video src="../assets/images/icons/Video.png" alt="Post video" />
                <Title>Upload My First Video</Title>
              </StyledCard> */}
              </Layout>
            </OuterForm2>
          </OuterForm1>
          )}

          <Container>
            <Title>My Blog</Title>
            <hr />
            <Row>
              <Col sm={12} md={6} xl={4}>
                <AddCard onClick={() => { window.location.href = '/my-content/new'; }}>
                  <Write src="../assets/images/icons/Write.png" alt="Write post" />
                  <Title>Write A Post</Title>
                </AddCard>
              </Col>
              {contentData.map((a) => (
                <Col sm={12} md={6} xl={4}>
                  <ContentCard>
                    <ContentImage thumbnail={a.thumbnail_image}>
                      {a.status === 'PUBLISH' && (
                      <PublishedContent>Published</PublishedContent>
                      )}
                      {a.status === 'DRAFT' && (
                      <DraftContent>Draft</DraftContent>
                      )}
                    </ContentImage>
                    <ContentText>
                      <Padding>
                        {a.content_tag_list !== null && (
                        <ContentTag>{a.content_tag_list.map((b) => `#${b} `)}</ContentTag>
                        )}
                        <ContentHeader>
                          <ContentTitle onClick={() => { window.location.href = `/home/article?id=${a.id}`; }}>{a.title}</ContentTitle>
                          <ContentIconHead>
                            <Eye />
                            <Amount>{a.number_reader}</Amount>
                            <Share />
                            <Amount>0</Amount>
                          </ContentIconHead>
                        </ContentHeader>
                        <ContentSummary>{a.summary}</ContentSummary>
                      </Padding>
                      <ContentFooter>
                        <ContentDate>{modDate(a.modify_date)}</ContentDate>
                        <ContentIconFoot>
                          <Heart />
                          <Amount>{a.number_like}</Amount>
                          <Comment />
                          <Amount>{a.number_comment}</Amount>
                        </ContentIconFoot>
                        <ContentIconFoot>
                          <Edit onClick={() => { window.location.href = `/my-content/edit/?id=${a.id}`; }} />
                          <Trash onClick={() => { deleteContent(a, 'deleted'); }} />
                        </ContentIconFoot>
                      </ContentFooter>
                    </ContentText>
                  </ContentCard>
                </Col>
              ))}
            </Row>
            {/* <Title>My Playlist</Title>
        <hr />
        <Row>
          <Col sm={24} md={12} lg={8}>
            <ContentCard>
              <ContentImage>
                <DraftContent>Draft</DraftContent>
              </ContentImage>
              <Padding>
                <ContentHeader>
                  <ContentTitle>long established</ContentTitle>
                  <ContentIconHead>
                    <Eye />
                    <Amount>0</Amount>
                    <Share />
                    <Amount>0</Amount>
                  </ContentIconHead>
                </ContentHeader>
                <ContentSummary>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that....</ContentSummary>
              </Padding>
              <ContentFooter>
                <ContentDate>Jan 29th 2022</ContentDate>
                <ContentIconFoot>
                  <Heart />
                  <Amount>0</Amount>
                  <Comment />
                  <Amount>0</Amount>
                </ContentIconFoot>
                <ContentIconFoot>
                  <Edit />
                  <Trash />
                </ContentIconFoot>
              </ContentFooter>
            </ContentCard>
            <MoreButton>
              See more
              <Down />
            </MoreButton>
            <AddCard>
              <Video src="../assets/images/icons/Video.png" alt="Post video" />
              <Title>Upload A Video</Title>
            </AddCard>
          </Col>
        </Row> */}
            <StyledPagination size="small" current={current} onChange={onPageChange} total={totalPage} pageSize={5} />
          </Container>
        </div>
      ) : (
        <Loading />
      )}
      <Footer />
    </>
  );
};

export default Content;
