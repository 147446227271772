/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-undef */
/* eslint-disable no-console */
import React, { useEffect, useLayoutEffect, useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-grid-system';
import { AiOutlineEye, AiOutlineShareAlt, AiOutlineHeart } from 'react-icons/ai';
import Navbar from '../modules/Navbar';
import Footer from '../components/Footer';
import HighlightSection from '../modules/HighlightSection';
import Card from '../components/Card';
import Loading from './Loading';

const Background = styled.div`
  display: table;
  width: 100%;
  min-width: 350px;
  height: 525px;
  background-color: #F7FAFC;
  margin-bottom: 1em;
`;

const VerticalMiddle = styled.div`
  display: table-cell;
  vertical-align: middle;
`;

const TopHeader = styled.h1`
  font-size: 60px;
  font-weight: bold;
  line-height: 1.25;
  margin: 0;
  cursor: default;
`;

const Paragraph = styled.p`
  color: #4A5568;
  font-size: 30px;
  margin: 0;
  cursor: default;
`;

const StyledCard = styled(Card)`
  min-width: 350px;
  margin-top: 2em;
  margin-bottom: 2em;
  padding: 0;
`;

const TopText = styled.div`
  display: flex;
  min-height: 240px;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 24px;
`;

const TopReadBG = styled.div`
  width: 110px;
  background-color: #B9AFDB20;
  border-radius: 20px;
  text-align: center;
  margin-bottom: 1em;
`;

const TopReadText = styled.p`
  color: #5D5FEF;
  font-size: 16px;
  margin: 0;
  cursor: default;
`;

const Title = styled.h1`
  color: black;
  font-size: 30px;
  font-weight: bold;
  cursor: default;
`;

const ContentCard = styled(Card)`
  width: 350px;
  min-height: 425px;
  margin: 0 auto;
  margin-top: 1.5em;
  margin-bottom: 1.5em;
  padding: 0;
`;

export type ThumbnailProps = {
  thumbnail?: string
};

const ContentImage = styled.div<ThumbnailProps>`
  width: 100%;
  height: 200px;
  background-image: ${(props) => (props.thumbnail ? `url(${props.thumbnail});` : "url('../assets/images/backgrounds/HeadSection.png');")};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;   
  border-radius: 8px 8px 0px 0px;
`;

const TopImage = styled.div<ThumbnailProps>`
  width: 100%;
  height: 240px;
  background-image: ${(props) => (props.thumbnail ? `url(${props.thumbnail});` : "url('../assets/images/backgrounds/HeadSection.png');")};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;   
  border-radius: 0;
`;

const ContentText = styled.div`
  display: flex;
  min-height: 220px;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 10px;
`;

const Padding = styled.div`
  padding: 10px 24px 10px 24px;
`;

const ContentTag = styled.p`
  color: #04C35C;
  font-size: 14px;
  font-weight: 500;
  margin: 0;
  cursor: default;
`;

const ContentHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ContentTitle = styled.h1`
  color: #2D3748;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 0.5em;
  cursor: default;
`;

const StyledRow = styled(Row)`
  padding-top: 4px;
`;

const Eye = styled(AiOutlineEye)`
  color: #616161;
  font-size: 20px;
  margin-right: 4px;
  cursor: pointer;
  &:hover {
    color: #1890ff;
  }
`;

const Share = styled(AiOutlineShareAlt)`
  color: #616161;
  font-size: 20px;
  margin-right: 4px;
  cursor: pointer;
  &:hover {
    color: #1890ff;
  }
`;

const ContentSummary = styled.p`
  color: #718096;
  font-size: 14px;
  margin: 0;
  cursor: default;
`;

const ContentFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 24px;
  padding-right: 24px;
`;

const ContentAuthor = styled.p`
  font-size: 16px;
  margin: 0;
  cursor: default;
`;

const ContentDate = styled.p`
  color: #718096;
  font-size: 12px;
  margin: 0;
  cursor: default;
`;

const ReadMore = styled.p`
  font-size: 12px;
  font-weight: bold;
  margin: 0;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const Heart = styled(AiOutlineHeart)`
  color: #616161;
  font-size: 20px;
  margin-right: 4px;
  cursor: pointer;
  &:hover {
    color: #1890ff;
  }
`;

const Amount = styled.p`
  color: #718096;
  font-size: 10px;
  margin: 0;
  margin-right: 8px;
  padding-top: 2px;
  cursor: default;
`;

const Home = () => {
  const [contentData, setContentData] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);

  useLayoutEffect(() => {
    (async () => {
      const restop = await axios.get(`${process.env.REACT_APP_WEB_API_URL}/api/v1/content/top_read?page=1&per_page=4`,
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem('token')}`,
          },
        });
      console.log(restop);
      setContentData(restop.data.result);
    })();
  }, []);

  useEffect(() => {
    setTimeout(() => setLoading(false), 2000);
  }, []);

  const modDate = (date) => {
    const dateTemp = new Date(date);
    const temp = new Intl.DateTimeFormat('en-US', {
      year: 'numeric', month: 'long', day: '2-digit',
    }).format(dateTemp);
    return temp;
  };

  return (
    <>
      <Navbar />
      {loading === false ? (
        <div>
          <Background>
            <VerticalMiddle>
              <Container>
                <TopHeader>Digital Revolution: </TopHeader>
                <TopHeader>ML, Power, & You</TopHeader>
                <Paragraph>Share your skills & Earn ML solution with us, OpenCloudAI</Paragraph>
              </Container>
            </VerticalMiddle>
          </Background>
          <Container>
            <Row>
              <Col>
                {contentData.slice(0, 1).map((top) => (
                  <StyledCard>
                    <Row>
                      <Col sm={12} md={12} lg={5} xl={4}>
                        <TopImage thumbnail={top.thumbnail_image} />
                      </Col>
                      <Col sm={12} md={12} lg={7} xl={8}>
                        <TopText>
                          <Padding>
                            <TopReadBG>
                              <TopReadText>Top read</TopReadText>
                            </TopReadBG>
                            {top.content_tag_list !== null && (
                            <ContentTag>{top.content_tag_list.map((t) => `#${t} `)}</ContentTag>
                            )}
                            <Title>{top.title}</Title>
                            <ContentSummary>{top.summary}</ContentSummary>
                          </Padding>
                          <ContentFooter>
                            <ContentDate>{modDate(top.modify_date)}</ContentDate>
                            <Row>
                              <Eye />
                              <Amount>{top.number_reader}</Amount>
                              <Share />
                              <Amount>0</Amount>
                              <Heart />
                              <Amount>{top.number_like}</Amount>
                            </Row>
                            <ReadMore onClick={() => { window.location.href = `/home/article?id=${top.id}`; }}>Read more</ReadMore>
                          </ContentFooter>
                        </TopText>
                      </Col>
                    </Row>
                  </StyledCard>
                ))}
              </Col>
            </Row>
          </Container>
          <Container>
            <Row>
              {contentData.slice(1, 4).map((a) => (
                <Col md={12} xl={4}>
                  <ContentCard>
                    <ContentImage thumbnail={a.thumbnail_image} />
                    <ContentText>
                      <Padding>
                        {a.content_tag_list !== null && (
                        <ContentTag>{a.content_tag_list.map((b) => `#${b} `)}</ContentTag>
                        )}
                        <ContentHeader>
                          <ContentTitle>{a.title}</ContentTitle>
                          <StyledRow>
                            <Heart />
                            <Amount>{a.number_like}</Amount>
                            <Eye />
                            <Amount>{a.number_reader}</Amount>
                            <Share />
                            <Amount>0</Amount>
                          </StyledRow>
                        </ContentHeader>
                        <ContentSummary>{a.summary}</ContentSummary>
                      </Padding>
                      <ContentFooter>
                        <div>
                          <ContentAuthor>{a.create_by}</ContentAuthor>
                          <hr />
                          <ContentDate>{modDate(a.modify_date)}</ContentDate>
                        </div>
                        <ReadMore onClick={() => { window.location.href = `/home/article?id=${a.id}`; }}>Read more</ReadMore>
                      </ContentFooter>
                    </ContentText>
                  </ContentCard>
                </Col>
              ))}
            </Row>
          </Container>
          <Container>
            <HighlightSection />
          </Container>
        </div>
      ) : (
        <Loading />
      )}
      <Footer />
    </>
  );
};

export default Home;
